<template>
    <QuestionCardWrapper
        class="question-card-si"
        :is-focused="question.isFocused"
        :is-invalid="!question.isValid() && !question.isFocused"
        :questionsContainer="questionsContainer"
        :questionIndex="questionIndex"
        v-click-outside="onClickOutside"
    >
        <template #content>
            <transition name="question-fade">
                <div class="question-card-content-header" v-if="!question.isFocused">
                    <button class="question-card-content-header--button draggable-list-handle">
                        <inline-svg src="/media/buying/icons/drag-and-drop-icon.svg" />
                    </button>
                    <span class="question-card-content-header--error" v-if="question.validationMessage">{{ $t(question.validationMessage) }}</span>
                </div>
            </transition>

            <div class="question-card-content-top">
                <CustomQuestionsProductAreaSelect
                    v-model="question.productArea"
                    :options="questionsContainer.surveyProductAreas"
                    @onChange="$emit('onProductAreaChange')"
                />
                <CustomQuestionTypeSelect
                    :value="question.type"
                    @onChange="$emit('onTypeChange', $event)"
                />
            </div>

            <QuestionCardTitleInput
                v-model="question.text"
                :max-length="question.customQuestionTextMaxLength"
                :read-only="!question.isFocused"
            />

            <div class="question-card-si__options" :class="{'multi-select': question.isMultiSelect, 'is-read-only': !question.isFocused}" >
                <draggable
                    :list="question.options"
                    item-key="name"
                    class="options-list"
                    ghost-class="ghost"
                    handle=".drag-handle"
                >
                    <div class="options-list__item" v-for="element in question.options" :class="{'is-invalid': !element.isValid() && element.isItemTouched}">
                        <div class="options-list__item--icon">
                            <div class="drag-handle">
                                <inline-svg src="/media/buying/icons/drag-and-drop-icon.svg" />
                            </div>

                            <div class="options-icon"></div>
                        </div>
                        <div class="options-list__item--input">
                            <template v-if="question.isFocused">
                                <input type="text" v-model="element.value" @input="element.setItemTouche()">
                                <div class="input-length-text">{{ element.value.length }}/{{ element.itemValueMaxLength }} {{ $t('Characters') }}</div>
                            </template>
                            <span v-else>{{ element.value }}</span>

                            <div class="remove-options-button" @click="question.removeOption(element)">
                                <inline-svg src="/media/buying/icons/close.svg" width="20" height="20" />
                            </div>
                        </div>
                    </div>
                </draggable>

                <div class="question-card-si__options--add">
                    <div class="add-button-icon">
                        <div class="options-icon"></div>
                    </div>
                    <button
                        @click="question.addOption()"
                        :disabled="optionsAddButtonStatus"
                    >
                        + {{ $t('Add Option') }}
                    </button>
                </div>
            </div>
        </template>
        <template #footer>
            <QuestionCardSwitcher
                v-model="question.isMultiSelect"
                :label="$t('Allow respondents to select multiple answers')"
            />

            <QuestionCardSwitcher
                v-model="question.isRequired"
                :label="$t('Required field')"
            />

            <QuestionCardActionBtn
                icon="/media/buying/icons/content-duplicate.svg"
                :text="$t('Duplicate')"
                @handleClick="$emit('onDuplicate')"
            />

            <QuestionCardActionBtn
                icon="/media/buying/icons/delete-icon.svg"
                :text="$t('Delete')"
                @handleClick="$emit('onDelete')"
            />
        </template>
    </QuestionCardWrapper>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next'
import QuestionCardWrapper from "@/buying-teams/shared-components/custom-questions/components/QuestionCardWrapper";
import CustomQuestionsProductAreaSelect from "@/buying-teams/shared-components/custom-questions/components/CustomQuestionsProductAreaSelect";
import CustomQuestionTypeSelect from "@/buying-teams/shared-components/custom-questions/components/CustomQuestionTypeSelect";
import QuestionCardTitleInput from "@/buying-teams/shared-components/custom-questions/components/QuestionCardTitleInput";
import QuestionCardSwitcher from "@/buying-teams/shared-components/custom-questions/components/QuestionCardSwitcher";
import QuestionCardActionBtn from "@/buying-teams/shared-components/custom-questions/components/QuestionCardActionBtn";
import vClickOutside from "click-outside-vue3";
import { FSCustomQuestionCreateMultipleChoice } from "@/store/models/shared/feedback-seesion/custom-question/FSCustomQuestionCreateMultipleChoice";
import { CustomQuestionsContainer } from "@/store/models/shared/CustomQuestionsContainer";

export default {
    name: "QuestionCardMultipleChoice",
    components: {
        QuestionCardActionBtn,
        QuestionCardSwitcher,
        QuestionCardTitleInput,
        CustomQuestionTypeSelect,
        CustomQuestionsProductAreaSelect,
        QuestionCardWrapper,
        draggable: VueDraggableNext
    },
    emits: ['onDuplicate', 'onDelete', 'onTypeChange', 'onProductAreaChange'],
    props: {
        question: {
            type: [Object, FSCustomQuestionCreateMultipleChoice],
            default: false
        },
        questionsContainer: {
            type: [Object, CustomQuestionsContainer],
            required: true,
        },
        questionIndex: {
            type: Number,
            required: true,
        }
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    computed: {
        optionsAddButtonStatus() {
            return (this.question.options.length && !this.question.areOptionsValid()) || this.question.options.length === this.question.maxOptionsLength
        }
    },
    methods: {
        onClickOutside() {
            this.question.setFocus(false);
            this.questionsContainer.questionDateChange(this.question, this.questionIndex);
        }
    }
}
</script>

<style lang="scss">
.question-card-si {
    &__options {
        margin-top: 12px;

        .options-icon {
            width: 16px;
            height: 16px;
            border: 1px solid #D9D9D9;
            border-radius: 50%;
            transition: .3s;
        }

        &--add {
            display: flex;
            align-items: center;
            height: 54px;
            margin-bottom: 40px;
            .add-button-icon {
                width: 55px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-right: 12px;
            }
            button {
                background: transparent;
                border: none;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #435BF4;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                display: flex;
                width: calc(100% - 55px);
                height: 54px;
                align-items: center;
                transition: .2s ease-in-out;
                &:disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
                &:not(:disabled):hover {
                    border-color: #000000;
                }
            }
        }

        .options-list {
            &__item {
                display: flex;
                &--icon {
                    width: 55px;
                    height: 54px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding-right: 12px;
                    gap: 12px;
                    .drag-handle {
                        cursor: move;
                    }
                }
                &--input {
                    width: calc(100% - 55px);
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    input {
                        height: 54px;
                        width: 100%;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 15px;
                        color: #2B2B2B;
                        outline: none;
                        border: none;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                        transition: .2s ease-in-out;
                        background: transparent;
                        &:focus {
                            border-color: #000000;
                        }
                    }
                    span {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        color: #666666;
                    }
                    .remove-options-button {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        cursor: pointer;
                        &:hover {
                            svg path {
                                fill: #000;
                            }
                        }
                    }

                    .input-length-text {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        color: #666666;
                        margin-top: 4px;
                    }
                }

                &.ghost {
                    background: #eff0ff;
                    > * {
                        opacity: 0;
                    }
                }

                &.is-invalid {
                    .options-list__item--input {
                        input {
                            border-color: #E22D21;
                        }
                        .input-length-text {
                            color: #E22D21;
                        }
                    }
                }
            }
        }

        &.multi-select {
            .options-icon {
                border-radius: 3px;
            }
        }

        &.is-read-only {
            .question-card-si__options--add {
                display: none;
            }
            .options-list {
                &__item {
                    height: 34px;
                    &--icon {
                        width: 34px;
                        height: auto;
                        padding-right: 18px;
                        .drag-handle {
                            display: none;
                        }
                    }
                    &--input {
                        justify-content: center;
                        .remove-options-button {
                            display: none;
                        }
                    }
                    &.is-invalid {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
