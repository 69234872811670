<template>
    <el-drawer
        v-model="visible"
        size="773px"
        :with-header="false"
        :before-close="beforeClose"
        custom-class="prev-questions-drawer"
    >
        <div class="prev-questions-drawer__header">
            <div class="prev-questions-drawer__header--title">
                <button class="cancel-button" @click="beforeClose">
                    <img src="/media/buying/icons/close.svg" alt="">
                </button>
                <h4>{{ $t('Previous Questions') }}</h4>
                <span>{{ questionsContainer.savedQuestions.length }}</span>
            </div>

            <button
                class="prev-questions-drawer__header--select-all"
                :disabled="!questionsContainer.savedQuestions.length"
                @click="handleSelectAll"
            >
                <span v-if="selectedItems.length === questionsContainer.savedQuestions.length">
                    {{ $t('Deselect all') }}
                </span>
                <span v-else>{{ $t('Select all') }}</span>
            </button>
        </div>

        <div class="prev-questions-drawer__search">
            <inline-svg src="/media/buying/icons/search-black.svg" />
            <input type="text" v-model="search" :placeholder="$t('Search Questions or ID')">
        </div>

        <el-checkbox-group v-model="selectedItems">
            <div class="prev-questions-drawer__content">
                <PreviousQuestionItem
                    v-for="(question, index) in filteredItems"
                    :key="index"
                    :question="question"
                >
                    <template #checkbox>
                        <el-checkbox size="large" :label="question.id" />
                    </template>
                </PreviousQuestionItem>
            </div>
        </el-checkbox-group>

        <div class="prev-questions-drawer__footer">
            <div class="prev-questions-drawer__footer--info">
                {{ $t('Previous questions cannot be edited.') }}
            </div>
            <div class="prev-questions-drawer__footer--actions">
                <button class="actions-button btn-outline" @click="beforeClose">
                    + {{ $t('Create New') }}
                </button>
                <button class="actions-button btn-bg" :disabled="!selectedItems.length" @click="handleAddSelectedQuestions">
                    {{ $t('Add all selected') }}

                    <span v-if="selectedItems.length">{{ selectedItems.length }}</span>
                </button>
            </div>
        </div>
    </el-drawer>
</template>

<script>
import PreviousQuestionItem from "@/buying-teams/shared-components/custom-questions/components/PreviousQuestionItem";
import { FeedbackSessionQuestionsTypeEnum } from "@/store/enums/FeedbackSessionQuestionsTypeEnum";
import { CustomQuestionsContainer } from "@/store/models/shared/CustomQuestionsContainer";

export default {
    name: "PreviousQuestionsDrawer",
    components: {
        PreviousQuestionItem
    },
    props: {
        modelValue: Boolean,
        questionsContainer: {
            type: [Object, CustomQuestionsContainer],
            required: true,
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            search: '',
            selectedItems: [],
            visible: false,
            FeedbackSessionQuestionsTypeEnum
        }
    },
    computed: {
        filteredItems() {
            if (!this.search) return this.questionsContainer.savedQuestions;
            else {
                return this.questionsContainer.savedQuestions.filter(item => item.text.toLowerCase().includes(this.search.toLowerCase()))
            }
        }
    },
    methods: {
        beforeClose() {
            this.$emit('update:modelValue', false)
        },
        handleSelectAll() {
            if (this.selectedItems.length === this.questionsContainer.savedQuestions.length) {
                this.selectedItems = [];
            } else {
                this.selectedItems = this.questionsContainer.savedQuestions.map(q => q.id);
            }
        },
        handleAddSelectedQuestions() {
            let questions = this.questionsContainer.savedQuestions.filter(q => this.selectedItems.includes(q.id));
            this.questionsContainer.setSavedQuestions(questions);
            this.selectedItems = [];
            this.questionsContainer.findDuplicateQuestionsAndValidate();
            this.$emit('update:modelValue', false);
        }
    },
    watch: {
        modelValue() {
            this.visible = this.modelValue;
        },
    }
}
</script>

<style lang="scss">
.prev-questions-drawer {
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 78px 60px;
        padding-bottom: 0;
        &--title {
            display: flex;
            align-items: center;
            .cancel-button {
                width: 20px;
                height: 20px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                background: transparent;
                margin-right: 13px;
                img {
                    width: 100%;
                }
            }
            h4 {
                font-weight: 700;
                font-size: 22px;
                line-height: 27px;
                color: #000000;
                margin-right: 13px;
                margin-bottom: 0;
            }
            span {
                background: #ECECEC;
                border-radius: 3px;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #000000;
                padding: 3px 4px;
            }
        }
        &--select-all {
            padding: 0;
            background: none;
            border: none;
            font-weight: 600;
            font-size: 16px;
            line-height: 158.02%;
            color: #435BF4;
        }
    }
    &__search {
        background: #FAFAFA;
        border-radius: 29px;
        height: 42px;
        position: relative;
        margin-top: 26px;
        margin-right: 60px;
        margin-left: 60px;
        svg {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
        input {
            width: 100%;
            height: 100%;
            outline: none;
            background: transparent;
            border-radius: 29px;
            border: none;
            padding: 10px 10px 10px 52px;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #000;
            &::placeholder {
                color: #C4C4C4;
            }
        }
    }
    &__content {
        padding: 0 60px;
        padding-bottom: 200px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 12px;
        overflow-y: auto;
        max-height: calc(100vh - 186px);
        .el-checkbox {
            .el-checkbox__label {
                display: none;
            }
        }
    }
    &__footer {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        background: #FFFFFF;
        box-shadow: 0px -2px 18px rgba(0, 0, 0, 0.1);
        padding: 24px;
        z-index: 2;
        &--info {
            margin-bottom: 26px;
            font-weight: 400;
            font-size: 12px;
            line-height: 26px;
            text-align: center;
            color: rgba(0, 0, 0, 0.6);
        }
        &--actions {
            display: flex;
            justify-content: center;
            align-items: center;
            .actions-button {
                border-radius: 30px;
                height: 48px;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                padding: 14px 46px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                transition: .3s ease-in-out;
                position: relative;

                span {
                    position: absolute;
                    top: -10px;
                    right: 0;
                    background: #E22D21;
                    border: 1px solid #FFFFFF;
                    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 15px;
                    color: #FFFFFF;
                    border-radius: 50%;
                    height: 21px;
                    min-width: 21px;
                    padding: 3px 6px;
                }

                &.btn-outline {
                    background: transparent;
                    color: #435BF4;
                }

                &.btn-bg {
                    background: #435BF4;
                    color: #fff;
                }

                &:not(:disabled):hover {
                    opacity: 0.8;
                }

                &:disabled {
                    opacity: 0.7;
                }
            }
        }
    }
    .el-drawer__body {
        padding: 0;
        position: relative;
    }

    @media (max-width: 774px) {
        width: 100% !important;
        &__header {
            padding-top: 50px;
            padding-left: 24px;
            padding-right: 24px;
        }
        &__search {
            margin-left: 24px;
            margin-right: 24px;
        }
        &__content {
            padding-left: 24px;
            padding-right: 24px;
            padding-bottom: 150px;
        }
    }

    @media (max-width: 768px) {
        &__header {
            &--title {
                h4 {
                    font-size: 18px;
                    line-height: 120%;
                }
                span {
                    font-size: 14px;
                    line-height: 120%;
                }
            }
        }
        &__footer {
            .actions-button {
                font-size: 14px;
                padding: 20px;
                height: 40px;
            }
        }
    }
}
</style>
