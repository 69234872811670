import { FeedbackSessionQuestionsTypeEnum } from "@/store/enums/FeedbackSessionQuestionsTypeEnum";
import { CUSTOM_QUESTIONS_VALIDATION } from "@/core/config/constants";

export class FSCustomQuestionCreateBase {
    customQuestionDefaultRequirementState: boolean = false;
    customQuestionTextMaxLength: number = CUSTOM_QUESTIONS_VALIDATION.QUESTION_TEXT_MAX_LENGTH;
    customQuestionDefaultType: FeedbackSessionQuestionsTypeEnum = FeedbackSessionQuestionsTypeEnum.SCORE_IMPORTANCE;

    id: number | undefined;
    text: string;
    isFocused: boolean = false;
    isSaved: boolean = false;
    isRequired: boolean;
    type: FeedbackSessionQuestionsTypeEnum;
    productArea: string = '';
    initialDataJSON: string | null = null;
    isDuplicated: boolean = false;

    constructor(obj?: any) {
        this.id = obj ? obj.id : null;
        this.text = obj ? obj.text : '';
        this.isRequired = obj ? obj.is_required : this.customQuestionDefaultRequirementState;
        this.type = obj ? obj.type : this.customQuestionDefaultType;
        this.productArea = obj ? obj.product_area : '';
        this.isSaved = obj ? !!obj.is_saved : false;

        if (this.isSaved) {
            this.setInitialDataJSON(obj);
        }
    }

    get validationMessageBase() {
        if (this.isDuplicated) {
            return 'Please modify or remove the duplicate question'
        } else if (!this.productArea) {
            return 'Please select Product Area to add this question to the session'
        } else if (!this.text.length) {
            return 'Please enter a question';
        } else {
            return ''
        }
    }

    get basePayloadData() {
        return {
            type: this.type,
            product_area: this.productArea,
            text: this.text,
            is_required: this.isRequired,
            is_saved: this.isSaved,
            id: this.id,
        }
    }

    setInitialDataJSON(data: any) {
        if (!data) return;

        delete data.id;
        delete data.is_saved;
        if (data.type === FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE) {
            data.options = data.options.sort();
        }
        if (data.type === FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE_GRID) {
            data.columns = data.columns.sort();
            data.rows = data.rows.sort();
        }
        this.initialDataJSON = JSON.stringify(data, Object.keys(data).sort());
    }

    setFocus(isFocused: boolean = true) {
        this.isFocused = isFocused;
    }

    setDuplicated(isDuplicated: boolean = true) {
        this.isDuplicated = isDuplicated;
    }

    isBaseValid() {
        return !!this.text && this.text.length <= this.customQuestionTextMaxLength && this.productArea && !this.isDuplicated;
    }

    cleanSavedData() {
        this.id = undefined;
        this.isSaved = false;
        this.initialDataJSON = null;
    }
}
