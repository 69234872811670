import { FSCustomQuestionCreateBase } from "@/store/models/shared/feedback-seesion/custom-question/FSCustomQuestionCreateBase";
import {
    FSCustomQuestionCreateMultipleChoiceItem
} from "@/store/models/shared/feedback-seesion/custom-question/FSCustomQuestionCreateMultipleChoiceItem";
import {CUSTOM_QUESTIONS_VALIDATION} from "@/core/config/constants";

export class FSCustomQuestionCreateMultipleChoiceGrid extends FSCustomQuestionCreateBase {
    columns: FSCustomQuestionCreateMultipleChoiceItem[] = [];
    rows: FSCustomQuestionCreateMultipleChoiceItem[] = [];
    minRowsLength: number = CUSTOM_QUESTIONS_VALIDATION.ROWS_MIN_LENGTH;
    minColumnsLength: number = CUSTOM_QUESTIONS_VALIDATION.COLUMNS_MIN_LENGTH;
    maxRowsLength: number = CUSTOM_QUESTIONS_VALIDATION.ROWS_MAX_LENGTH;
    maxColumnsLength: number = CUSTOM_QUESTIONS_VALIDATION.COLUMNS_MAX_LENGTH;
    defaultRowCount: number = CUSTOM_QUESTIONS_VALIDATION.MULTIPLE_CHOICE_GRID_DEFAULT_ROW_COUNT;
    defaultColumnsCount: number = CUSTOM_QUESTIONS_VALIDATION.MULTIPLE_CHOICE_GRID_DEFAULT_COLUMNS_COUNT;

    constructor(obj?: any) {
        super(obj);

        if (obj && obj.columns && obj.rows) {
            this.columns = obj.columns.map(opt => new FSCustomQuestionCreateMultipleChoiceItem(opt));
            this.rows = obj.rows.map(opt => new FSCustomQuestionCreateMultipleChoiceItem(opt, true));
        } else {
            for (let row = 1; row <= this.defaultRowCount; row ++) {
                this.rows.push(new FSCustomQuestionCreateMultipleChoiceItem(`Statement ${row}`, true));
            }
            for (let column = 1; column <= this.defaultColumnsCount; column ++) {
                this.columns.push(new FSCustomQuestionCreateMultipleChoiceItem(`Option ${column}`));
            }
        }
    }

    get validationMessage() {
        if (this.validationMessageBase) {
            return this.validationMessageBase
        } else if (this.columns.length < this.minColumnsLength) {
            return 'Please add at least 1 option';
        } else if (this.rows.length < this.minRowsLength) {
            return 'Please add at least 1 statement';
        } else if (!this.areColumnsValid()) {
            return 'Option missing title. Please check'
        } else if (!this.areRowsValid()) {
            return 'Statement missing title. Please check'
        } else {
            return '';
        }
    }

    get payloadData() {
        return {
            ...this.basePayloadData,
            columns: this.columns.map(c => c.value),
            rows: this.rows.map(c => c.value),
        };
    }

    get childInitialDataJSON() {
        let itemDataJSON: any = {...this.payloadData};
        itemDataJSON.columns = itemDataJSON.columns.sort();
        itemDataJSON.rows = itemDataJSON.rows.sort();
        delete itemDataJSON.id;
        delete itemDataJSON.is_saved;

        return JSON.stringify(itemDataJSON, Object.keys(itemDataJSON).sort());
    }

    get isChanged() {
        if (!this.initialDataJSON) return false;

        return this.childInitialDataJSON !== this.initialDataJSON;
    }

    get showAddColumButton() {
        return this.columns.length < this.maxColumnsLength;
    }

    get showAddRowButton() {
        return this.rows.length < this.maxRowsLength;
    }

    addColumn(columnName) {
        if (this.columns.length >= this.maxColumnsLength) return;
        const column = new FSCustomQuestionCreateMultipleChoiceItem(columnName);
        this.columns.push(column);
    }

    removeColumn(columnIndex) {
        this.columns.splice(columnIndex, 1);
    }

    addRow(rowName) {
        if (this.rows.length >= this.maxRowsLength) return;
        const row = new FSCustomQuestionCreateMultipleChoiceItem(rowName, true);
        this.rows.push(row);
    }

    removeRow(rowIndex) {
        this.rows.splice(rowIndex, 1);
    }

    areColumnsValid() {
        return this.columns.length > 0 && this.columns.every(opt => opt.isValid());
    }

    areRowsValid() {
        return this.rows.length > 0 && this.rows.every(opt => opt.isValid())
    }

    isValid() {
        return this.areColumnsValid() &&
            this.columns.length >= this.minColumnsLength &&
            this.rows.length >= this.minRowsLength &&
            this.areRowsValid() &&
            this.isBaseValid();
    }
}
