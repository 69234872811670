<template>
    <div class="question-card" :class="{'is-focused': isFocused, 'is-invalid': isInvalid}">
        <div class="question-card__content" @click="handleClickQuestion">
            <slot name="content"></slot>
        </div>
        <div class="question-card__footer" v-if="isFocused">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
import { CustomQuestionsContainer } from "@/store/models/shared/CustomQuestionsContainer";

export default {
    name: "QuestionCardWrapper",
    props: {
        isFocused: {
            type: Boolean,
            default: false
        },
        isInvalid: {
            type: Boolean,
            default: false
        },
        questionIndex: {
            type: Number,
            required: true,
        },
        questionsContainer: {
            type: [Object, CustomQuestionsContainer],
            required: true,
        },
    },
    methods: {
        handleClickQuestion() {
            if (!this.isFocused) {
                this.questionsContainer.focusQuestion(this.questionIndex);
            }
        }
    }
}
</script>

<style lang="scss">
.question-card {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    transition: .3s ease-in-out;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:after {
        content: "";
        position: absolute;
        width: 5px;
        top: 0;
        left: 0;
        bottom: 0;
        background: #435BF4;
        visibility: hidden;
        transform: translateX(-5px);
        transition: .3s;
    }
    &:not(.is-focused):hover {
        border-color: #d7d7d7 !important;
        background: #fbfbfb;
    }
    &__content {
        padding: 16px;
        padding-top: 39px;
        position: relative;
        transition: .1s;

        .question-fade-enter-active, .question-fade-leave-active {
            transition: opacity .1s;
        }
        .question-fade-enter, .question-fade-leave-to {
            opacity: 0;
        }

        .question-card-content-header {
            margin-bottom: 25px;
            display: flex;
            align-items: center;
            position: absolute;
            top: 16px;
            z-index: 1;
            &--button {
                border: 0;
                background: transparent;
                margin-right: 6px;
                display: flex;
                align-items: center;
                cursor: move;
            }
            &--error {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #CE2419;
            }
        }
        .question-card-content-top {
            display: flex;
            gap: 17px;
            margin-bottom: 16px;
        }
    }
    &__footer {
        padding: 18px 22px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 30px;
    }
    &.is-focused {
        cursor: default;
        &:after {
            visibility: visible;
            transform: translateX(0);
        }
        .question-card__content {
            padding-top: 16px;
        }
    }
    &.is-invalid {
        border-color: #E22D21;
        &.is-focused:after {
            display: none;
        }
    }

    @media (max-width: 1200px) {
        &__content {
            .question-card-content-top {
                flex-wrap: wrap;
            }
        }
    }
}
</style>
