import { FSCustomQuestionCreateBase } from "@/store/models/shared/feedback-seesion/custom-question/FSCustomQuestionCreateBase";

export class FSCustomQuestionCreateTextOnly extends FSCustomQuestionCreateBase {
    constructor(obj?: any) {
        super(obj);
    }

    get validationMessage() {
        return this.validationMessageBase;
    }

    get payloadData() {
        return this.basePayloadData;
    }

    get childInitialDataJSON() {
        let itemDataJSON: any = {...this.payloadData};
        delete itemDataJSON.id;
        delete itemDataJSON.is_saved;

        return JSON.stringify(itemDataJSON, Object.keys(itemDataJSON).sort());
    }

    get isChanged() {
        if (!this.initialDataJSON) return false;

        return this.childInitialDataJSON !== this.initialDataJSON;
    }

    isValid() {
        return this.isBaseValid();
    }
}

