<template>
    <QuestionCardWrapper
        class="question-card-si"
        :is-focused="question.isFocused"
        :is-invalid="!question.isValid() && !question.isFocused"
        :questionsContainer="questionsContainer"
        :questionIndex="questionIndex"
        v-click-outside="onClickOutside"
    >
        <template #content>
            <transition name="question-fade">
                <div class="question-card-content-header" v-if="!question.isFocused">
                    <button class="question-card-content-header--button draggable-list-handle">
                        <inline-svg src="/media/buying/icons/drag-and-drop-icon.svg" />
                    </button>
                    <span class="question-card-content-header--error" v-if="question.validationMessage">{{ $t(question.validationMessage) }}</span>
                </div>
            </transition>

            <div class="question-card-content-top">
                <CustomQuestionsProductAreaSelect
                    v-model="question.productArea"
                    :options="questionsContainer.surveyProductAreas"
                    @onChange="$emit('onProductAreaChange')"
                />
                <CustomQuestionTypeSelect
                    :value="question.type"
                    @onChange="$emit('onTypeChange', $event)"
                />
            </div>

            <QuestionCardTitleInput
                v-model="question.text"
                :max-length="question.customQuestionTextMaxLength"
                :read-only="!question.isFocused"
            />

            <div class="question-card-si__text" v-if="!question.isFocused">
                {{ $t('This is an open ended question, users can write upto 300 characters when they reply to answer this.') }}
            </div>
        </template>
        <template #footer>
            <QuestionCardSwitcher
                v-model="question.isRequired"
                :label="$t('Required field')"
            />

            <QuestionCardActionBtn
                icon="/media/buying/icons/content-duplicate.svg"
                :text="$t('Duplicate')"
                @handleClick="$emit('onDuplicate')"
            />

            <QuestionCardActionBtn
                icon="/media/buying/icons/delete-icon.svg"
                :text="$t('Delete')"
                @handleClick="$emit('onDelete')"
            />
        </template>
    </QuestionCardWrapper>
</template>

<script>
import QuestionCardWrapper from "@/buying-teams/shared-components/custom-questions/components/QuestionCardWrapper";
import CustomQuestionsProductAreaSelect from "@/buying-teams/shared-components/custom-questions/components/CustomQuestionsProductAreaSelect";
import CustomQuestionTypeSelect from "@/buying-teams/shared-components/custom-questions/components/CustomQuestionTypeSelect";
import QuestionCardTitleInput from "@/buying-teams/shared-components/custom-questions/components/QuestionCardTitleInput";
import QuestionCardSwitcher from "@/buying-teams/shared-components/custom-questions/components/QuestionCardSwitcher";
import QuestionCardActionBtn from "@/buying-teams/shared-components/custom-questions/components/QuestionCardActionBtn";
import vClickOutside from "click-outside-vue3";
import { FSCustomQuestionCreateTextOnly } from "@/store/models/shared/feedback-seesion/custom-question/FSCustomQuestionCreateTextOnly";
import { CustomQuestionsContainer } from "@/store/models/shared/CustomQuestionsContainer";

export default {
    name: "QuestionCardTextOnly",
    components: {
        QuestionCardActionBtn,
        QuestionCardSwitcher,
        QuestionCardTitleInput,
        CustomQuestionTypeSelect,
        CustomQuestionsProductAreaSelect,
        QuestionCardWrapper
    },
    emits: ['onDuplicate', 'onDelete', 'onTypeChange', 'onProductAreaChange'],
    props: {
        question: {
            type: [Object, FSCustomQuestionCreateTextOnly],
            default: false
        },
        questionsContainer: {
            type: [Object, CustomQuestionsContainer],
            required: true,
        },
        questionIndex: {
            type: Number,
            required: true,
        }
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    methods: {
        onClickOutside() {
            this.question.setFocus(false);
            this.questionsContainer.questionDateChange(this.question, this.questionIndex);
        }
    }
}
</script>

<style scoped lang="scss">
.question-card-si {
    &__text {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 7px;
        padding: 30px 26px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #272727;
        margin-top: 26px;
        margin-bottom: 6px;
    }
}
</style>
