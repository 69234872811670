<template>
    <div class="question-card-switch">
        <el-switch :model-value="modelValue" @change="handleChange"></el-switch>
        <span>{{ label }}</span>
    </div>
</template>

<script>
export default {
    name: "QuestionCardSwitcher",
    props: {
        modelValue: Boolean,
        label: String
    },
    emits: ['update:modelValue'],
    methods: {
        handleChange(value) {
            this.$emit('update:modelValue', value);
        }
    }
}
</script>

<style lang="scss">
.question-card-switch {
    display: flex;
    align-items: center;
    gap: 10px;
    > span {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
    }
    .el-switch__core {
        width: 34px !important;
        height: 20px !important;
        .el-switch__action {
            width: 16px;
            height: 16px;
        }
    }
    .el-switch.is-checked .el-switch__core .el-switch__action {
        left: 120%;
    }
}
</style>
