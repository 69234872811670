import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29ba611a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "prev-question-item" }
const _hoisted_2 = { class: "prev-question-item__left" }
const _hoisted_3 = { class: "prev-question-item__right" }
const _hoisted_4 = { class: "prev-question-item__type" }
const _hoisted_5 = { class: "question-label" }
const _hoisted_6 = { class: "prev-question-item__title" }
const _hoisted_7 = ["title"]
const _hoisted_8 = { class: "question-id" }
const _hoisted_9 = {
  key: 0,
  class: "prev-question-item__options"
}
const _hoisted_10 = { class: "options-section" }
const _hoisted_11 = { class: "options-section__label" }
const _hoisted_12 = { class: "options-section__value" }
const _hoisted_13 = { class: "options-section" }
const _hoisted_14 = { class: "options-section__label" }
const _hoisted_15 = { class: "options-section__value" }
const _hoisted_16 = {
  key: 1,
  class: "prev-question-item__options"
}
const _hoisted_17 = { class: "options-section" }
const _hoisted_18 = { class: "options-section__label" }
const _hoisted_19 = { class: "options-section__value" }
const _hoisted_20 = {
  key: 2,
  class: "prev-question-item__product-area"
}
const _hoisted_21 = { class: "product-area-list" }
const _hoisted_22 = { class: "product-area-list__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "checkbox", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('Question Type')), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.questionTypeLabel), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", {
          title: _ctx.question.text
        }, _toDisplayString(_ctx.question.text), 9, _hoisted_7),
        _createElementVNode("div", _hoisted_8, [
          _createTextVNode(_toDisplayString(_ctx.$t("ID")) + " ", 1),
          _createElementVNode("strong", null, _toDisplayString(_ctx.question.id), 1)
        ])
      ]),
      (_ctx.question.type === _ctx.FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE_GRID)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('Statements')) + ":", 1),
              _createElementVNode("div", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.rows, (row) => {
                  return (_openBlock(), _createElementBlock("span", null, _toDisplayString(row.value), 1))
                }), 256))
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('Options')) + ":", 1),
              _createElementVNode("div", _hoisted_15, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.columns, (col) => {
                  return (_openBlock(), _createElementBlock("span", null, _toDisplayString(col.value), 1))
                }), 256))
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.question.type === _ctx.FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('Options')) + ":", 1),
              _createElementVNode("div", _hoisted_19, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.options, (option) => {
                  return (_openBlock(), _createElementBlock("span", null, _toDisplayString(option.value), 1))
                }), 256))
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.question.productArea)
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('Product Area')), 1),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.question.productArea), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}