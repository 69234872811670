import { FeedbackSessionQuestionsTypeEnum } from "@/store/enums/FeedbackSessionQuestionsTypeEnum";
import { FSCustomQuestionCreateScoreImportance } from "@/store/models/shared/feedback-seesion/custom-question/FSCustomQuestionCreateScoreImportance";
import { FSCustomQuestionCreateTextOnly } from "@/store/models/shared/feedback-seesion/custom-question/FSCustomQuestionCreateTextOnly";
import { FSCustomQuestionCreateMultipleChoice } from "@/store/models/shared/feedback-seesion/custom-question/FSCustomQuestionCreateMultipleChoice";
import { FSCustomQuestionCreateMultipleChoiceGrid } from "@/store/models/shared/feedback-seesion/custom-question/FSCustomQuestionCreateMultipleChoiceGrid";
import store from "@/store";

export type QuestionBaseType =
    FSCustomQuestionCreateScoreImportance |
    FSCustomQuestionCreateTextOnly |
    FSCustomQuestionCreateMultipleChoice |
    FSCustomQuestionCreateMultipleChoiceGrid;

export class CustomQuestionsContainer {
    surveyProductAreas: string[];
    savedQuestions: QuestionBaseType[] = [];
    newQuestions: QuestionBaseType[] = [];

    constructor(data: any, productAreas: string[] = []) {
        this.surveyProductAreas = productAreas;
        this.setSavedQuestionsData(data);
        console.log({'CustomQuestionsContainer this => ': this});
    }

    get isNewQuestionsValid() {
        return this.newQuestions.every((q) => q.isValid());
    }

    get payloadDataForAdd() {
        const payload = this.newQuestions
            .filter((item) => (!item.isSaved || (item.isSaved && item.isChanged)) && item.isValid())
            .map((item) => item.payloadData);
        return payload ? payload  : [];
    }

    resetQuestionContainer(surveyProductAreas) {
        this.surveyProductAreas = surveyProductAreas;
        this.savedQuestions = [];
        this.newQuestions = [];
    }

    getPayloadDataForAddSurvey(newCustomQuestions: any[] = []) {
        let savedCustomQuestions: any[] = this.newQuestions.filter(question => (question.isSaved && !question.isChanged) && question.isValid());
        savedCustomQuestions = savedCustomQuestions ? savedCustomQuestions.map(question => {
            return {
                id: question.id,
                text: question.text
            }
        }) : [];

        return [
            ...newCustomQuestions,
            ...savedCustomQuestions,
        ]
    }

    setSavedQuestionsData(data: any[] = []) {
        if (data && data.length > 0) {
            this.savedQuestions = [];
            for (let i = 0 ; i < data.length; i++) {
                const question = data[i];

                if (question) {
                    let questionModel: QuestionBaseType | null = this.getQuestionModel(
                        question.type,
                        this.getCleanedData({...question, is_saved: true})
                    );

                    if (questionModel) {
                        this.savedQuestions.push(questionModel);
                    } else {
                        console.error('failed CustomQuestionsContainer initialization');
                    }
                }
            }
        }
    }

    getCleanedData(question) {
        let newQuestionData = {...question};
        delete newQuestionData.business_id;
        delete newQuestionData.created_by;
        delete newQuestionData.created_at;
        delete newQuestionData.updated_by;
        delete newQuestionData.updated_at;
        return newQuestionData;
    }

    unFocusAll() {
        this.newQuestions.map((q: QuestionBaseType) => {
            q.setFocus(false);
        })
    }

    focusQuestion(index: number) {
        if (this.newQuestions.length) {
            this.unFocusAll();
            this.newQuestions[index]?.setFocus(true);
        }
    }

    addQuestion() {
        let newQuestion = new FSCustomQuestionCreateScoreImportance();
        this.unFocusAll();
        newQuestion.setFocus(true);
        this.newQuestions.push(newQuestion);
    }

    questionTypeToggle(questionIndex, type) {
        const savedQuestion: QuestionBaseType = this.newQuestions[questionIndex];
        let savedData: any = {
            type: type,
            product_area: savedQuestion.productArea,
            text: savedQuestion.text,
            is_required: savedQuestion.isRequired,
            is_saved: false,
        }
        if (savedQuestion.id) {
            savedData.id = savedQuestion.id;
        }

        this.unFocusAll();

        let updatedQuestion = this.getQuestionModel(type, savedData);

        if (updatedQuestion) {
            updatedQuestion.setFocus(true);
            this.newQuestions[questionIndex] = updatedQuestion;
        } else {
            console.error('failed questionTypeToggle');
        }
    }

    duplicateQuestion(question: QuestionBaseType, shouldFocus: boolean = true, setSaved: boolean = false) {
        let savedData: any = {
            ...question.payloadData,
            is_saved: setSaved ? question.payloadData.is_saved : false
        }
        question.setFocus(false);

        return this.getQuestionModel(question.type, savedData);
    }

    setDuplicateQuestion(question: QuestionBaseType, shouldFocus: boolean = true, setSaved: boolean = false) {
        const newQuestion: QuestionBaseType | null = this.duplicateQuestion(question, shouldFocus, setSaved);

        if (newQuestion) {
            if (shouldFocus) {
                newQuestion.setFocus(true);
            }
            this.newQuestions.push(newQuestion);
        } else {
            console.error('failed duplicateQuestion');
        }
    }

    deleteQuestion(questionIndex) {
        this.newQuestions.splice(questionIndex, 1);
    }

    setSavedQuestions(questions: QuestionBaseType[]) {
        questions.forEach((q: QuestionBaseType) => {
            this.setDuplicateQuestion(q, false, true);
        })
    }

    getQuestionModel(type, initialData) {
        switch (type) {
            case FeedbackSessionQuestionsTypeEnum.SCORE_IMPORTANCE:
                return new FSCustomQuestionCreateScoreImportance(initialData)
            case FeedbackSessionQuestionsTypeEnum.TEXT_ONLY:
                return new FSCustomQuestionCreateTextOnly(initialData)
            case FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE:
                return new FSCustomQuestionCreateMultipleChoice(initialData)
            case FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE_GRID:
                return new FSCustomQuestionCreateMultipleChoiceGrid(initialData)
            default:
                console.error('UNDEFINED CUSTOM QUESTION TYPE');
                return null;
        }
    }

    questionDateChange(currentQuestion: QuestionBaseType, questionIndex: number) {
        this.savedQuestions.forEach((question: QuestionBaseType) => {
            if (question.initialDataJSON === currentQuestion.childInitialDataJSON) {
                const newQuestion: QuestionBaseType | null = this.duplicateQuestion(question, false, true);
                if (newQuestion) {
                    this.newQuestions.splice(questionIndex, 1, newQuestion);
                }
            } else {
                currentQuestion.cleanSavedData();
            }
        });

        this.findDuplicateQuestionsAndValidate();
    }

    findDuplicateQuestionsAndValidate() {
        const questionsMap = new Map();
        this.newQuestions.forEach((q) => {
            if (questionsMap.has(q.childInitialDataJSON)) {
                q.setDuplicated();
            } else {
                questionsMap.set(q.childInitialDataJSON, true);
                q.setDuplicated(false);
            }
        })
    }

    // This solution is for all platforms (Bank, Business, Super Admin);
    launchQuestions(surveyId: number) {
        return new Promise(async (resolve) => {
            let newCustomQuestions = [];

            if (this.payloadDataForAdd.length) {
                newCustomQuestions = await store.dispatch('addCustomQuestion', this.payloadDataForAdd);
            }

            let payloadCustomQuestions = this.getPayloadDataForAddSurvey(newCustomQuestions);

            if (payloadCustomQuestions && payloadCustomQuestions.length) {
                try {
                    await store.dispatch('addCustomQuestionToSurvey', {
                        survey_id: surveyId,
                        questions: payloadCustomQuestions
                    })
                } catch {
                    resolve(payloadCustomQuestions);
                }
            }

            resolve(payloadCustomQuestions);
        })
    }
}
