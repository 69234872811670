import { CUSTOM_QUESTIONS_VALIDATION } from "@/core/config/constants";

export class FSCustomQuestionCreateMultipleChoiceItem {
    isItemTouched: boolean = false;
    itemValueMaxLength: number;
    value: string;

    constructor(value, isRow = false) {
        this.value = value || '';
        if (isRow) {
            this.itemValueMaxLength = CUSTOM_QUESTIONS_VALIDATION.MULTIPLE_CHOICE_GRID_ROW_TEXT_MAX_LENGTH;
        } else {
            this.itemValueMaxLength = CUSTOM_QUESTIONS_VALIDATION.MULTIPLE_CHOICE_TEXT_MAX_LENGTH;
        }
    }

    setItemTouche(value: boolean = true) {
        if (!value) {
            setTimeout(() => {
                this.isItemTouched = value;
            }, 200);
        } else {
            this.isItemTouched = value;
        }
    }

    isValid() {
        return !!this.value && this.value.length <= this.itemValueMaxLength;
    }
}
