<template>
    <div class="question-card-input-input" :class="{'read-only': readOnly}">
        <input
            type="text"
            :value="modelValue"
            :placeholder="$t('Type your Question here')"
            :readonly="readOnly"
            @input="handleChange"
        >
        <div class="question-card-input-input--info">
            {{ modelValue.length }}/{{ maxLength }} {{ $t('Characters') }}
        </div>
    </div>
</template>

<script>
export default {
    name: "QuestionCardTitleInput",
    props: {
        modelValue: String,
        label: String,
        maxLength: Number,
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue'],
    methods: {
        handleChange(e) {
            this.$emit('update:modelValue', e.target.value);
        }
    }
}
</script>

<style lang="scss">
.question-card-input-input {
    input {
        width: 100%;
        outline: none;
        padding: 26px;
        background: #F6F7FE;
        border: 1px solid #435BF4;
        border-radius: 7px;
        height: 82px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #272727;
        transition: .1s;
        &::placeholder {
            color: #272727;
        }
        &:read-only {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: #272727;
            padding: 0;
            border: none;
            height: 29px;
            background: transparent;
        }
    }
    &--info {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #666666;
        margin-top: 12px;
        transition: .1s;
        visibility: visible;
        opacity: 1;
    }
    &.read-only {
        .question-card-input-input--info {
            visibility: hidden;
            opacity: 0;
            height: 0;
            margin-top: 0;
        }
    }
}

@media (max-width: 992px) {
    .question-card-input-input input:read-only {
        font-size: 18px;
    }
}
</style>
