<template>
    <div class="custom-questions-select">
        <el-select v-model="modelValue" @change="handleSelect" :placeholder="$t('Select Type')">
            <template #prefix>
                <img :src="getIcon" alt="">
            </template>
            <el-option
                v-for="item in options"
                :key="item.value"
                :value="item.value"
                :label="item.label"
            >
                <template #default>
                    <el-tooltip
                        placement="left-end"
                        effect="light"
                        popper-class="custom-questions-tooltip"
                        :disabled="item.value !== FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE_GRID"
                    >
                        <template #content>
                            <p>{{ $t('Likert is used to gauge attitudes and opinions about a topic.') }}</p>
                            <img src="https://cdn.forms.office.net/forms/images/question_backgrounds/likert_tooltip.png" width="200" alt="">
                        </template>

                        <div class="custom-questions-select__item">
                            <div class="custom-questions-select__item--icon">
                                <img :src="`/media/buying/icons/custom-question-type/${item.value}.svg`" alt="">
                            </div>
                            <span>{{ item.label }}</span>

                            <div class="el-tooltip-icon" v-if="item.value === FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE_GRID">
                                <inline-svg src="/media/buying/icons/info_black.svg" />
                            </div>
                        </div>
                    </el-tooltip>
                </template>
            </el-option>
        </el-select>
    </div>
</template>

<script>
import { FeedbackSessionQuestionsTypeEnum } from "@/store/enums/FeedbackSessionQuestionsTypeEnum";

export default {
    name: "CustomQuestionTypeSelect",
    emits: ["onChange"],
    props: {
        value: String,
    },
    data() {
        return {
            modelValue: '',
            FeedbackSessionQuestionsTypeEnum,
        }
    },
    created() {
        this.modelValue = this.value;
    },
    computed: {
        getIcon() {
            return this.modelValue
                ? `/media/buying/icons/custom-question-type/${this.modelValue}.svg`
                : '/media/buying/icons/custom-question-type/no-selection.svg';
        },
        options() {
            return [
                {
                    value: FeedbackSessionQuestionsTypeEnum.SCORE_IMPORTANCE,
                    label: this.$t('Score & Importance')
                },
                {
                    value: FeedbackSessionQuestionsTypeEnum.TEXT_ONLY,
                    label: this.$t('Text Only')
                },
                {
                    value: FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE,
                    label: this.$t('Single and Multiple Choice')
                },
                {
                    value: FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE_GRID,
                    label: this.$t('Likert')
                }
            ]
        }
    },
    methods: {
        handleSelect() {
            this.$emit('onChange', this.modelValue)
        }
    },
}
</script>

<style lang="scss">
.custom-questions-tooltip {
    display: flex;
    flex-direction: column;
    max-width: 255px;
    p {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #666666;
        margin-bottom: 10px;
    }
    img {
        width: 100%;
    }
}
.custom-questions-select {
    .el-input__inner, .el-input__inner::placeholder {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #2B2B2B;
    }
    .el-input__inner {
        background: #F8F8F8;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 7px;
        min-width: 317px;
        padding-left: 60px !important;
    }
    .el-select__tags {
        display: none;
    }
    .el-input__prefix {
        width: 60px;
        cursor: pointer;
        left: 0;
    }
    .el-input__prefix-inner, .el-input__prefix-inner > div {
        width: 100%;
    }
    .el-input__suffix {
        right: 15px;
    }
    &__item {
        display: flex;
        align-items: center;
        height: 100%;
        position: relative;
        margin-left: -20px;
        padding-left: 20px;
        &--icon {
            width: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
        }
        span {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #2B2B2B;
        }

        .el-tooltip-icon {
            position: absolute;
            right: -20px;
        }
    }
}
.el-select-dropdown__item {
    &.selected {
        background-color: var(--el-bg-color);
        .custom-questions-select__item span {
            color: #435BF4;
        }
    }
}
</style>
