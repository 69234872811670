<template>
    <button class="question-card-button" @click="$emit('handleClick')">
        <inline-svg width="24" height="24" :src="icon" />
        <span>{{ text }}</span>
    </button>
</template>

<script>
export default {
    name: "QuestionCardActionBtn",
    props: {
        text: String,
        icon: String,
    },
    emits: ['handleClick']
}
</script>

<style lang="scss">
.question-card-button {
    padding: 4px;
    border-radius: 4px;
    border: none;
    display: flex;
    align-items: center;
    background: transparent;
    transition: .3s ease-in-out;
    &:not(:disabled):hover {
        background: rgb(249 249 249);
    }
    span {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        margin-left: 10px;
    }
    svg path {
        fill: #435BF4;
    }
}
</style>
