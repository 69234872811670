<template>
    <div class="custom-questions-product-select">
        <el-select v-model="modelValue" filterable @change="handleSelect">
            <template #prefix>
                <inline-svg src="/media/buying/icons/product-areas-icon.svg" width="23" height="23" />
                <span>{{ modelValue ? modelValue : $t('Select Product Areas') }}</span>
            </template>
            <el-option
                v-for="item in options"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
        </el-select>
    </div>
</template>

<script>
export default {
    name: "CustomQuestionsProductAreaSelect",
    emits: ["update:modelValue", "onChange"],
    props: {
        modelValue: String,
        options: {
            type: Array,
            default: []
        }
    },
    methods: {
        handleSelect() {
            this.$emit('update:modelValue', this.modelValue);
            this.$emit('onChange', this.modelValue);
        }
    }
}
</script>

<style lang="scss">
.custom-questions-product-select {
    position: relative;
    &__label {
        position: absolute;
        background: #E22D21;
        border: 1px solid #FFFFFF;
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
        z-index: 1;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: #FFFFFF;
        height: 21px;
        min-width: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        padding: 4px;
        right: 0;
        top: -10px;
    }
    .el-input__inner, .el-input__inner::placeholder {
        color: transparent;
    }
    .el-input__inner {
        background: #F8F8F8;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 7px;
        width: 317px;
        padding-left: 0 !important;
    }
    .el-select__tags {
        display: none;
    }
    .el-input__prefix {
        width: 100%;
        cursor: pointer;
        left: 0;
    }
    .el-input__prefix-inner, .el-input__prefix-inner > div {
        width: 100%;
    }
    .el-input__suffix {
        right: 15px;
    }
    .el-input__prefix-inner > div {
        justify-content: flex-start !important;
        padding: 15px 18px;
        svg path {
            fill: #D9D9D9;
        }
        span {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #2B2B2B;
            margin-left: 18px;
        }
    }
}
</style>
