import { FSCustomQuestionCreateBase } from "@/store/models/shared/feedback-seesion/custom-question/FSCustomQuestionCreateBase";
import {
    FSCustomQuestionCreateMultipleChoiceItem
} from "@/store/models/shared/feedback-seesion/custom-question/FSCustomQuestionCreateMultipleChoiceItem";
import { CUSTOM_QUESTIONS_VALIDATION } from "@/core/config/constants";

export class FSCustomQuestionCreateMultipleChoice extends FSCustomQuestionCreateBase {
    options: FSCustomQuestionCreateMultipleChoiceItem[];
    isMultiSelect: boolean = false;
    maxOptionsLength: number = CUSTOM_QUESTIONS_VALIDATION.OPTIONS_MAX_LENGTH;
    minOptionsLength: number = CUSTOM_QUESTIONS_VALIDATION.OPTIONS_MIN_LENGTH;

    constructor(obj?: any) {
        super(obj);

        this.options = (obj && obj.options) ?
            obj.options.map(opt => new FSCustomQuestionCreateMultipleChoiceItem(opt)) :
            [];
        this.isMultiSelect = obj ? !!obj.is_multi_select : false;
    }

    get validationMessage() {
        if (this.validationMessageBase) {
            return this.validationMessageBase
        } else if (!this.areOptionsValid() || this.options.length < this.minOptionsLength) {
            return 'Please add at least 2 options';
        } else {
            return '';
        }
    }

    get payloadData() {
        return {
            ...this.basePayloadData,
            options: this.options.map(o => o.value),
            is_multi_select: this.isMultiSelect,
        };
    }

    get childInitialDataJSON() {
        let itemDataJSON: any = {...this.payloadData};
        itemDataJSON.options = itemDataJSON.options.sort();
        delete itemDataJSON.id;
        delete itemDataJSON.is_saved;

        return JSON.stringify(itemDataJSON, Object.keys(itemDataJSON).sort());
    }

    get isChanged() {
        if (!this.initialDataJSON) return false;

        return this.childInitialDataJSON !== this.initialDataJSON;
    }

    addOption(optionName) {
        if (this.options.length >= this.maxOptionsLength) return;
        const option = new FSCustomQuestionCreateMultipleChoiceItem(optionName);
        this.options.push(option);
    }

    removeOption(option) {
        this.options = this.options.filter(opt => opt.value !== option.value);
    }

    areOptionsValid() {
        return this.options.length > 0 && this.options.every(opt => opt.isValid());
    }

    isValid() {
        return this.areOptionsValid() && this.options.length >= this.minOptionsLength && this.isBaseValid();
    }
}

