<template>
    <div class="custom-questions">
        <div class="custom-questions__top">
            <button @click="handleBack">
                <inline-svg src="/media/buying/icons/arrow-left.svg" width="8" height="14" />
                {{ $t('Back to step NUM', { num: backToNumber }) }}
            </button>
        </div>
        <div class="custom-questions__header">
            <div class="custom-questions__header--title">
                <h2>{{ $t("Custom Questions") }}</h2>
                <p>{{ $t("You can add your own personalized questions in the Session.") }}</p>
            </div>
            <button
                :disabled="!questionsContainer.savedQuestions.length"
                class="custom-questions__header--button"
                @click="prevQuestionsModal = true"
            >
                <inline-svg src="/media/buying/icons/reset-icon.svg" />
                <span>{{ $t('Add Previous Questions') }}</span>
                <span class="questions-count" v-if="questionsContainer.savedQuestions.length">
                    {{ questionsContainer.savedQuestions.length }}
                </span>
            </button>
        </div>

        <div class="custom-questions__content">

            <draggable
                :list="questionsContainer.newQuestions"
                handle=".draggable-list-handle"
                class="draggable-list"
                :force-fallback="true"
                :animation="200"
                @start="drag = true"
                @end="drag = false"
            >
                <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                    <div
                        class="draggable-list__item"
                        :key="index"
                        :id="question.id"
                        :ref="'questionItem_' + (index + 1)"
                        v-for="(question, index) in questionsContainer.newQuestions"
                    >
                        <component
                            :is="getComponentName(question.type)"
                            :question="question"
                            :questionIndex="index"
                            :questionsContainer="questionsContainer"
                            @onTypeChange="handleTypeChange(index, $event)"
                            @onProductAreaChange="handleProductAreaChange(question)"
                            @onDuplicate="handleDuplicate(question, index)"
                            @onDelete="handleDelete(index)"
                        />
                    </div>
                </transition-group>
            </draggable>
        </div>

        <button class="custom-questions__add-question" @click="handleAddQuestion">
            + {{ $t('Add Question') }}
        </button>

        <div class="d-flex justify-content-center mt-8">
            <button
                class="btn main-btn continue-with-selected"
                :disabled="questionsContainer.newQuestions.length && !this.questionsContainer.isNewQuestionsValid"
                @click="handleContinueWithSelectedQuestions"
            >
                {{ $t('Continue with Selected Questions') }}
                <span v-if="questionsContainer.newQuestions.length">
                    {{ questionsContainer.newQuestions.length }}
                </span>
            </button>
        </div>

        <PreviousQuestionsDrawer
            :questionsContainer="questionsContainer"
            v-model="prevQuestionsModal"
        />
    </div>
</template>

<script>
import store from "@/store";
import { StaticDataEnum } from "@/store/enums/StaticDataEnum";
import PreviousQuestionsDrawer from "@/buying-teams/shared-components/custom-questions/components/PreviousQuestionsDrawer";
import QuestionCardScoreImportance from "@/buying-teams/shared-components/custom-questions/cards/QuestionCardScoreImportance";
import QuestionCardMultipleChoice from "@/buying-teams/shared-components/custom-questions/cards/QuestionCardMultipleChoice";
import QuestionCardTextOnly from "@/buying-teams/shared-components/custom-questions/cards/QuestionCardTextOnly";
import QuestionCardMultipleChoiceGrid from "@/buying-teams/shared-components/custom-questions/cards/QuestionCardMultipleChoiceGrid";
import { VueDraggableNext } from 'vue-draggable-next'
import { CustomQuestionsContainer } from "@/store/models/shared/CustomQuestionsContainer";
import { FeedbackSessionQuestionsTypeEnum } from "@/store/enums/FeedbackSessionQuestionsTypeEnum";

export default {
    name: "CustomQuestionsContainer",
    components: {
        QuestionCardMultipleChoiceGrid,
        QuestionCardTextOnly,
        QuestionCardMultipleChoice,
        QuestionCardScoreImportance,
        PreviousQuestionsDrawer,
        draggable: VueDraggableNext
    },
    emits: ['handleBack', 'continueWithSelectedQuestions'],
    props: {
        questionsContainer: {
            type: [Object, CustomQuestionsContainer],
            required: true,
        },
        backToNumber: {
            type: Number,
            default: 3
        }
    },
    data() {
        return {
            FeedbackSessionQuestionsTypeEnum,
            pageLoading: true,
            prevQuestionsModal: false,
            drag: false,
        }
    },
    created() {
        window.scrollTo(0, 0);
        this.getStaticData();
    },
    methods: {
        getStaticData() {
            return Promise.all([
                store.dispatch('fetchStaticData', StaticDataEnum.PRODUCT_AREAS),
                store.dispatch('fetchStaticData', StaticDataEnum.COUNTRIES),
            ]).finally(() => {
                this.pageLoading = false;
            })
        },
        handleBack() {
            this.$emit('handleBack');
        },
        handleAddQuestion() {
            this.questionsContainer.addQuestion();
        },
        handleTypeChange(questionIndex, type) {
            this.questionsContainer.questionTypeToggle(questionIndex, type);
        },
        handleProductAreaChange(question) {
            this.questionsContainer.unFocusAll();
            question.setFocus();
        },
        handleDuplicate(question) {
            this.questionsContainer.setDuplicateQuestion(question);
            this.$nextTick(() => {
                let element = this.$refs[`questionItem_${this.questionsContainer.newQuestions.length}`];
                if (element && element.length) {
                    element[0].scrollIntoView({ behavior: "smooth", block: "center" });
                }
            })
        },
        handleDelete(index) {
            this.questionsContainer.deleteQuestion(index);
            this.questionsContainer.findDuplicateQuestionsAndValidate();
        },
        getComponentName(type) {
            switch (type) {
                case FeedbackSessionQuestionsTypeEnum.SCORE_IMPORTANCE:
                    return 'QuestionCardScoreImportance'
                case FeedbackSessionQuestionsTypeEnum.TEXT_ONLY:
                    return 'QuestionCardTextOnly'
                case FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE:
                    return 'QuestionCardMultipleChoice'
                case FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE_GRID:
                    return 'QuestionCardMultipleChoiceGrid'
            }
        },
        handleContinueWithSelectedQuestions() {
            this.questionsContainer.findDuplicateQuestionsAndValidate();
            if (this.questionsContainer.isNewQuestionsValid) {
                this.$emit('continueWithSelectedQuestions');
            }
        }
    },
}
</script>

<style scoped lang="scss">
.custom-questions {
    .flip-list-move {
        transition: transform 0.2s;
    }

    .no-move {
        transition: transform 0s;
    }

    &__top {
        margin-bottom: 36px;
        button {
            background: none;
            border: none;
            font-weight: 500;
            font-size: 18px;
            line-height: 170.02%;
            color: #435BF4;
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        &--title {
            h2 {
                font-weight: 700;
                font-size: 22px;
                line-height: 27px;
                color: #000000;
                margin-bottom: 12px;
            }
            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 178%;
                color: #595959;
                margin-bottom: 0;
            }
        }
        &--button {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #435BF4;
            border: 1px solid #435BF4;
            border-radius: 30px;
            background: transparent;
            padding: 15px 32px;
            display: flex;
            align-items: center;
            gap: 12px;
            transition: .3s;
            position: relative;
            .questions-count {
                position: absolute;
                top: -11px;
                right: -5px;
                background: #E22D21;
                border: 1px solid #FFFFFF;
                box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
                border-radius: 78px;
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;
                color: #FFFFFF;
                padding: 3px 6px;
                height: 22px;
                min-width: 22px;
            }
            &:not(:disabled):hover {
                background: #edefff;
            }
            &:disabled {
                filter: grayscale(1) opacity(0.5);
            }
        }
    }
    &__content {
        margin-bottom: 26px;

        .draggable-list {
            display: flex;
            flex-direction: column;
            gap: 26px;
            &__item {
                border-radius: 7px;
                &.sortable-ghost {
                    border: 1px solid rgba(67, 91, 244, 0.14);
                    background: rgba(220, 219, 255, 0.15);
                    > div {
                        opacity: 0;
                    }
                }
            }
        }
    }
    &__add-question {
        width: 100%;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #435BF4;
        text-align: left;
        background: #F8F8F8;
        border-radius: 7px;
        border: none;
        padding: 30px 20px;
        transition: .3s;
        &:hover {
            background: #ebebeb;
        }
    }
    .continue-with-selected {
        position: relative;
        span {
            position: absolute;
            top: -10px;
            right: -4px;
            background: #E22D21;
            border: 1px solid #FFFFFF;
            box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
            border-radius: 78px;
            min-width: 22px;
            height: 22px;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    @media (max-width: 1200px) {
        &__header {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            &--title {
                h2 {
                    margin-bottom: 0;
                }
            }
            &--button {
                padding: 10px 24px;
            }
        }
    }
}
</style>
