
import { PropType, defineComponent } from "vue";
import { FeedbackSessionQuestionsTypeEnum } from "@/store/enums/FeedbackSessionQuestionsTypeEnum";

export default defineComponent({
    name: "PreviousQuestionItem",
    props: {
        question: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            checked1: false,
            FeedbackSessionQuestionsTypeEnum
        }
    },
    computed: {
        questionTypeLabel() {
            switch (this.question.type) {
                case FeedbackSessionQuestionsTypeEnum.SCORE_IMPORTANCE: return this.$t('Score & Importance');
                case FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE_GRID: return this.$t('Likert');
                case FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE: {
                    return this.question.isMultiSelect ? this.$t('Multiple Choice') : this.$t('Single Choice');
                }
                case FeedbackSessionQuestionsTypeEnum.TEXT_ONLY: return this.$t('Text Only');
            }
        }
    }
})
