<template>
    <QuestionCardWrapper
        class="question-card-si"
        :is-focused="question.isFocused"
        :is-invalid="!question.isValid() && !question.isFocused"
        :questionsContainer="questionsContainer"
        :questionIndex="questionIndex"
        v-click-outside="onClickOutside"
    >
        <template #content>
            <transition name="question-fade">
                <div class="question-card-content-header" v-if="!question.isFocused">
                    <button class="question-card-content-header--button draggable-list-handle">
                        <inline-svg src="/media/buying/icons/drag-and-drop-icon.svg" />
                    </button>
                    <span class="question-card-content-header--error" v-if="question.validationMessage">{{ $t(question.validationMessage) }}</span>
                </div>
            </transition>

            <div class="question-card-content-top">
                <CustomQuestionsProductAreaSelect
                    v-model="question.productArea"
                    :options="questionsContainer.surveyProductAreas"
                    @onChange="$emit('onProductAreaChange')"
                />
                <CustomQuestionTypeSelect
                    :value="question.type"
                    @onChange="$emit('onTypeChange', $event)"
                />
            </div>

            <QuestionCardTitleInput
                v-model="question.text"
                :max-length="question.customQuestionTextMaxLength"
                :read-only="!question.isFocused"
            />

            <div
                class="column-row-table"
                :class="{'isFocused': question.isFocused, 'enabled-add-column': question.showAddColumButton}"
            >
                <table>
                    <thead>
                    <tr>
                        <td></td>
                        <td v-for="(col, index) in question.columns" class="table__header--item">
                            <div class="table-input" :class="{'isItemTouched': col.isItemTouched, 'is-invalid': !col.isValid()}">
                                <input
                                    v-model="col.value"
                                    type="text"
                                    :maxlength="col.itemValueMaxLength"
                                    :placeholder="$t('Option')"
                                    :readonly="!question.isFocused"
                                    @focus="col.setItemTouche()"
                                    @focusout="col.setItemTouche(false)"
                                >
                                <div class="not-visible-content">
                                    <div class="input-length-text">{{ col.value.length }}/{{ col.itemValueMaxLength }} {{ $t('Characters') }}</div>
                                    <div class="table-input-btn" @click="question.removeColumn(index)" v-if="question.columns.length > question.minColumnsLength">
                                        <inline-svg src="/media/buying/icons/delete-icon.svg" />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td v-if="question.showAddColumButton && question.isFocused">
                            <div class="w-100 d-flex justify-content-center align-items-center">
                                <button
                                    @click="question.addColumn(`${$t('Option')} ${question.columns.length + 1}`)"
                                    :disabled="columnsAddButtonStatus"
                                    class="add-column-button"
                                    :title="$t('Add Option')"
                                >
                                    <inline-svg src="/media/buying/icons/plus.svg" />
                                </button>
                            </div>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, index) in question.rows">
                        <td class="table__body--item">
                            <div class="table-input" :class="{'isItemTouched': row.isItemTouched, 'is-invalid': !row.isValid()}">
                                <input
                                    v-model="row.value"
                                    type="text"
                                    :maxlength="row.itemValueMaxLength"
                                    :placeholder="$t('Statement')"
                                    :readonly="!question.isFocused"
                                    @focus="row.setItemTouche()"
                                    @focusout="row.setItemTouche(false)"
                                >
                                <div class="not-visible-content">
                                    <div class="input-length-text">{{ row.value.length }}/{{ row.itemValueMaxLength }} {{ $t('Characters') }}</div>
                                    <div class="table-input-btn" @click="question.removeRow(index)" v-if="question.rows.length > question.minRowsLength">
                                        <inline-svg src="/media/buying/icons/delete-icon.svg" />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td v-for="col in question.columns">
                            <div class="w-100 d-flex justify-content-center">
                                <div class="column-row-table__radio"></div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <button
                v-if="question.showAddRowButton && question.isFocused"
                class="add-row-button"
                @click="question.addRow(`${$t('Statement')} ${question.rows.length + 1}`)"
                :disabled="rowsAddButtonStatus"
            >
                + {{ $t('Add Statement') }}
            </button>

        </template>
        <template #footer>
            <QuestionCardSwitcher
                v-model="question.isRequired"
                :label="$t('Required field')"
            />

            <QuestionCardActionBtn
                icon="/media/buying/icons/content-duplicate.svg"
                :text="$t('Duplicate')"
                @handleClick="$emit('onDuplicate')"
            />

            <QuestionCardActionBtn
                icon="/media/buying/icons/delete-icon.svg"
                :text="$t('Delete')"
                @handleClick="$emit('onDelete')"
            />
        </template>
    </QuestionCardWrapper>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next'
import QuestionCardWrapper from "@/buying-teams/shared-components/custom-questions/components/QuestionCardWrapper";
import CustomQuestionsProductAreaSelect from "@/buying-teams/shared-components/custom-questions/components/CustomQuestionsProductAreaSelect";
import CustomQuestionTypeSelect from "@/buying-teams/shared-components/custom-questions/components/CustomQuestionTypeSelect";
import QuestionCardTitleInput from "@/buying-teams/shared-components/custom-questions/components/QuestionCardTitleInput";
import QuestionCardSwitcher from "@/buying-teams/shared-components/custom-questions/components/QuestionCardSwitcher";
import QuestionCardActionBtn from "@/buying-teams/shared-components/custom-questions/components/QuestionCardActionBtn";
import vClickOutside from "click-outside-vue3";
import { FSCustomQuestionCreateMultipleChoiceGrid } from "@/store/models/shared/feedback-seesion/custom-question/FSCustomQuestionCreateMultipleChoiceGrid";
import { CustomQuestionsContainer } from "@/store/models/shared/CustomQuestionsContainer";

export default {
    name: "QuestionCardMultipleChoiceGrid",
    components: {
        QuestionCardActionBtn,
        QuestionCardSwitcher,
        QuestionCardTitleInput,
        CustomQuestionTypeSelect,
        CustomQuestionsProductAreaSelect,
        QuestionCardWrapper,
        draggable: VueDraggableNext
    },
    emits: ['onDuplicate', 'onDelete', 'onTypeChange', 'onProductAreaChange'],
    props: {
        question: {
            type: [Object, FSCustomQuestionCreateMultipleChoiceGrid],
            default: false
        },
        questionsContainer: {
            type: [Object, CustomQuestionsContainer],
            required: true,
        },
        questionIndex: {
            type: Number,
            required: true,
        }
    },
    computed: {
        rowsAddButtonStatus() {
            return (this.question.rows.length && !this.question.areRowsValid()) || this.question.rows.length === this.question.maxRowsLength
        },
        columnsAddButtonStatus() {
            return (this.question.columns.length && !this.question.areColumnsValid()) || this.question.columns.length === this.question.maxColumnsLength
        }
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    methods: {
        onClickOutside() {
            this.question.setFocus(false);
            this.questionsContainer.questionDateChange(this.question, this.questionIndex);
        }
    }
}
</script>

<style lang="scss">
.column-row {
    margin-top: 12px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    &__item {
        display: flex;
        &--icon {
            width: 47px;
            height: 54px;
            display: flex;
            align-items: center;
            padding-left: 5px;
            svg {
                cursor: move;
            }
        }
        &--input {
            width: calc(100% - 47px);
            position: relative;
            input {
                width: 100%;
                height: 54px;
                outline: none;
                border: none;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #666666;
                transition: .3s;
                &::placeholder {
                    color: #ccc;
                }
                &:focus {
                    border-color: #000000;
                }
            }
            button {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #435BF4;
                width: 100%;
                height: 100%;
                text-align: left;
                background: transparent;
                border: none;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                transition: .3s;
                &:disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
                &:not(:disabled):hover {
                    border-color: #000000;
                }
            }
            .input-length-text {
                margin-top: 6px;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #666666;
            }
            .remove-options-button {
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
                &:hover {
                    svg path {
                        fill: #000;
                    }
                }
            }
        }

        &.ghost {
            background: #eff0ff;
            > * {
                opacity: 0;
            }
        }

        &.is-invalid {
            .column-row__item--input {
                input {
                    border-color: #E22D21;
                }
                .input-length-text {
                    color: #E22D21;
                }
            }
        }
    }
}
.add-row-button {
    color: #435BF4;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: transparent;
    border: none;
    margin-top: 14px;
}
.column-row-table {
    table {
        width: 100%;
        tbody {
            tr {
                &:not(:last-child) {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                }
                td {
                    padding: 10px 0;
                }
            }
        }
    }
    &__radio {
        width: 16px;
        height: 16px;
        border: 1px solid #2B2B2B;
        border-radius: 50%;
    }
    .table__header--item {
        text-align: center;
        .table-input input {
            text-align: center;
        }
    }
    .add-column-button {
        margin: auto;
        background: transparent;
        border: none;
        svg {
            width: 20px;
            height: 20px;
        }
        &:disabled {
            svg path {
                fill: #aaaaaa;
            }
        }
    }
    .table-input {
        position: relative;
        .not-visible-content {
            transition: .2s;
            opacity: 0;
            visibility: hidden;
        }
        .input-length-text {
            position: absolute;
            font-size: 10px;
            left: 0;
            bottom: -18px;
        }
        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-image: url("/media/buying/icons/edit_black.svg");
            background-size: 100%;
            width: 15px;
            height: 15px;
            opacity: 0.5;
            display: none;
        }
        input {
            width: 100%;
            border: none;
            outline: none;
            border-bottom: 1px solid transparent;
            padding: 5px;
            height: 32px;
            background: transparent;
            color: #666;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            transition: 0.2s;
            cursor: pointer;
            &::placeholder {
                color: #ccc;
            }
        }
        .table-input-btn {
            position: absolute;
            right: 0;
            top: 0;
            height: 32px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            svg path {
                fill: #3e3e3e;
            }
            &:hover svg path {
                fill: #E22D21;
            }
        }
    }
    &.isFocused {
        .table-input {
            &.isItemTouched {
                .not-visible-content {
                    visibility: visible;
                    opacity: 1;
                }
                input {
                    border-bottom-color: #435BF4;
                    color: #3e3e3e !important;
                }
                &.is-invalid {
                    input {
                        border-bottom-color: #E22D21;
                    }
                    .input-length-text {
                        color: #E22D21;
                    }
                }
                &:after {
                    display: none !important;
                }
            }
            &.is-invalid {
                input {
                    color: #E22D21;
                }
            }
            &:hover {
                input {
                    border-bottom-color: #ccc;
                }
                &:after {
                    display: block;
                }
            }
        }
        table tbody tr td {
            padding: 22px 0;
        }
    }
    &.enabled-add-column {

    }
}

@media (max-width: 768px) {
    .column-row-table {
        &.isFocused {
            overflow-x: auto;
            table {
                width: 700px;
                tr td:nth-child(1) {
                    position: sticky;
                    left: 0;
                    background: #fff;
                    z-index: 1;
                    .table-input {
                        border-right: 1px solid #ccc;
                    }
                }
            }
        }
    }
}
</style>
